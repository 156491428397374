
// RightsReadWrite enum type
export enum RightsReadWrite {
  ADMIN = "ADMIN",
  WRITE = "WRITE",
  READ = "READ",
  LIMITED = "LIMITED",
  NONE = "NONE"
}

// Utility methods for comparing RightsReadWrite states
export const RightsReadWriteUtils = {
  isAdmin: (rights: 'true'|'false'| RightsReadWrite) => rights === RightsReadWrite.ADMIN || rights === 'true',
  canWrite: (rights: 'true'|'false'|RightsReadWrite) => rights === RightsReadWrite.WRITE || rights === RightsReadWrite.ADMIN || rights === 'true',
  canRead: (rights: 'true'|'false'|RightsReadWrite) => rights === RightsReadWrite.READ || rights === RightsReadWrite.ADMIN || rights === RightsReadWrite.WRITE || rights === 'true',
  isLimited: (rights: RightsReadWrite) => rights === RightsReadWrite.LIMITED,
  isNoneOrUndefined: (rights: 'true'|'false'|RightsReadWrite | undefined) => rights?.toString() === RightsReadWrite.NONE.toString() || rights === undefined || rights === 'false'
};

export type HRModule = {
  moduleId: 'HR'
  updateResourceInformation: boolean
  accessProfile: RightsReadWrite
  accessSalary: RightsReadWrite
  accessSkill: RightsReadWrite
  accessDocument: RightsReadWrite
  accessJobPath: RightsReadWrite
  resourceOverviewCompanyZone: string[]
  wageOverviewOfficeZone: string[]
  wageOverviewCompanyZone: string[]
  resourceOverviewOfficeZoneIds: any[]
  resourceOverviewCompanyZoneIds: any[]
  wageOverviewOfficeZoneIds: any[]
  wageOverviewCompanyZoneIds: any[]
  onboardingAccess: boolean
  alumniAccess: boolean
  statsAccess: boolean
  settingsAccess: boolean
  resourceOverview: string
  hidePersonnalData: boolean
  canSetSkill: boolean
  canEditProfile: boolean
  resourceProfileAccess: boolean
  wageAccess: boolean
  wageOverview: string
  isAccess: boolean
  resourceOverviewOfficeZone: any[]
  createResourceAccess: boolean
  canSeeOthersProjects: boolean
}

export type DashboardModule = {
  moduleId: 'DASHBOARD'
  isAccess: boolean
  productionAccess: boolean
  salesAccess: boolean
  cashFlowAccess: boolean
  resourceAccess: boolean
}

export type ProjectModule = {
  moduleId: 'PROJECT'
  projectOverviewOfficeZoneIds: any[]
  projectOverviewCompanyZoneIds: any[]
  isAccess: boolean
  createAccess: boolean
  planningResourceAccess: boolean
  planningResourceScopeAll: boolean
  statsAccess: boolean
  settingsAccess: boolean
  projectOverview: string
  overviewOnlyPublic: boolean
}

export type TimesheetModule = {
  moduleId: 'TIMESHEET'
  isAccess: boolean
  showAll: boolean
}

export type GlobalSettings = {
  userAccess: boolean
  profileAccess: boolean
  canGrantSuperAdminAccess: boolean
}

export type LeaveModule = {
  moduleId: 'LEAVE'
  isAccess: boolean
  showAll: boolean
  calendarAccess: boolean
  requestAccess: boolean
  editAllLeaves: boolean
  canSelfValidate: boolean
  canChooseValidator: boolean
  newRequestAccess: boolean
  leaveValidator: boolean
  bulkCreateAccess: boolean
}

export type EvaluationModule = {
  moduleId: 'EVALUATION'
  evaluationOverviewOfficeZoneIds: any[]
  isAccess: boolean
  showAll: boolean
  evaluationOverview: string
  create360: boolean
  createPeriod: boolean
  createProject: boolean
  canEditEvaluation: boolean
}

export type RecruitmentModule = {
  recruitmentOverviewOfficeZoneIds: any[]
  moduleId: 'RECRUITMENT'
  isAccess: boolean
  screeningAccess: boolean
  recruitmentOverview: string
  canGrantAdminAccess: boolean
  pipegAccess: boolean
  jobBoardAccess: boolean
  candidateBaseAccess: boolean
  candidateHistoryAccess: boolean
  canEditNotManaged: boolean
  jobBoardAdminAccess: boolean
  canDeleteInterview: boolean
  statsAccess: boolean
  canDecideInsteadOfRecruiterEvaluation: boolean
  canReopenEvaluation: boolean
  canDecideProcessResult: boolean
  canReopenProcess: boolean
  canDownloadInterviewCandidateFile: boolean
  recruitmentOverviewOfficeZone: { _id: string; display: string }[]
  settingsAcess: boolean
  createCandidateAccess: boolean
  processInterviews: {
    isAccess: boolean
    seeCandidateMessages: boolean
    editCandidateInformations: boolean
    seeActionsOnProcess: boolean
  }
}

export type ExportModule = {
  moduleId: 'EXPORT'
  isAccess: boolean
  canExportEvaluation: boolean
  canExportResource: boolean
}

export type ExpenseModule = {
  moduleId: 'EXPENSE'
  isAccess: boolean
  canCreateExpense: boolean
  canEditSettings: boolean
}

export type Modules = {
  hr: HRModule
  dashboard: DashboardModule
  project: ProjectModule
  timesheet: TimesheetModule
  globalSettings: GlobalSettings
  leave: LeaveModule
  evaluation: EvaluationModule
  recruitment: RecruitmentModule
  export: ExportModule
  expense: ExpenseModule
}
